import React, {useState} from 'react';
import '../src/static/css/styles.css';
import logo from '../src/static/img/Full_Logo.png'

// auto-import all images
const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../src/static/img', false, /\.(png|jpe?g|svg)$/));

const data = [
  {
    title: "MFPP 9x9x1 Compartment",
    image: images['MFPP9X9X1.jpg'],
    price: "$29/150 pcs"
  },
  {
    title: "MFPP 9x9x3 Compartment",
    image: images['MFPP9X9X3.jpg'],
    price: "$29/150 pcs"
  },
  {
    title: "MFPP 8x8x1 Compartment ",
    image: images['MFPP8X8X1.jpg'],
    price: "$27/150 pcs"
  },
  {
    title: "MFPP 8x8x3 Compartment",
    image: images['MFPP8x8x3.jpg'],
    price: "$27/150 pcs"
  },
  {
    title: "MFPP 9x6x1 Compartment",
    image: images['MFPP9x6x1.jpg'],
    price: "$26/200 pcs"

  },
  {
    title: "225 MFPP 6x6",
    image: images['225MFP6x6.jpg'],
    price: "$24/250 pcs"
  },{
    title: "224 MFPP 5.5x5.5",
    image: images['225MFPP5.5x5.5.jpg'],
    price: "$22/250 pcs"
  },
  {
    title: "48 oz Black Round Container",
    image: images['48ozBlackRoundContainer.jpg'],
    price: "$39/150 pcs"

  },
  {
    title: "32 oz Black Round Container",
    image: images['32ozBlackRoundContainer.jpg'],
    price: "$22/150 pcs"
  },
  {
    title: "24 oz Black Round Container",
    image: images['24ozBlackRoundContainer.jpg'],
    price: "$20/150 pcs"
  },
  {
    title: "32 oz White Round Container",
    image: images['32ozWhiteRoundContainer.jpg'],
    price: "$26/150 pcs"

  },
  {
    title: "24 oz White Round Container",
    image: images['24ozWhiteRoundContainer.jpg'],
    price: "$24/150 pcs"
  },
  {
    title: "16 oz White Round Container",
    image: images['16ozWhiteRoundContainer.jpg'],
    price: "$22/150 pcs"
  },{
    title: "48 oz Black Round Bowl",
    image: images['48ozBlackRoundBowl.jpg'],
    price: "$28/100 pcs"
  },
  {
    title: "24 oz Black Round Bowl",
    image: images['24ozBlackRoundBowl.jpg'],
    price: "$23/150 pcs"

  },
  {
    title: "32 oz HD Soup Container (Cup/Lid Included)",
    image: images['32ozHDSoupContainer(Cup:Lid_Included).jpg'],
    price: "$33/240 pcs"
  },
  {
    title: "24 oz HD Soup Container(Cup/Lid Included)",
    image: images['24ozSoupContainer(Cup:Lid_Included).jpg'],
    price: "$27/240 pcs"
  },
  {
    title: "16 oz HD Soup Container (Cup/Lid Included)",
    image: images['16ozHDSoupContainer(Cup:Lid_Included).jpg'],
    price: "$24/240 pcs"
  },
  {
    title: "12 oz HD Soup Container (Cup/Lid Included)",
    image: images['12ozHDSoupContainer(Cup:Lid_Included).jpg'],
    price: "$23/240 pcs"
  },
  {
    title: "8 oz HD Soup Container (Cup/Lid Included)",
    image: images['8_oz__HD_Soup_Container__(Cup:Lid_Included).jpg'],
    price: "$21/240 pcs"
  },
  {
    title: "5.5 oz Sauce Cup",
    image: images['5.5_oz__Sauce_Cup.jpg'],
    price: "$36/2500 pcs"
  },
  {
    title: "4 oz Sauce Cup",
    image: images['4_oz__Sauce_Cup.jpg'],
    price: "$33/2500 pcs"
  },
  {
    title: "3.25 Sauce Cup",
    image: images['3.25__Sauce_Cup.jpg'],
    price: "$28/2500 pcs"
  },
  {
    title: "2 oz Sauce Cup",
    image: images['2_oz__Sauce_Cup.jpg'],
    price: "$23/2500 pcs"
  },
  {
    title: "1.5 oz Sauce Cup",
    image: images['1.5_oz__Sauce_Cup.jpg'],
    price: "$20/2500 pcs"
  },
  {
    title: " 1 oz Sauce Cup",
    image: images['1.0_oz_Sauce_Cup.jpg'],
    price: "$17/2500 pcs"
  },
  {
    title: "0.75 oz Sauce Cup",
    image: images['0.75_oz_Sauce_Cup.jpg'],
    price: "$16/2500 pcs"
  },
  {
    title: "3.0-5.5 oz Lid",
    image: images['5.0-5.5_oz__Lid.jpg'],
    price: "$23/2500 pcs"
  },
  {
    title: "1.5-2.5 oz Lid",
    image: images['1.5-2.5_oz__Lid.jpg'],
    price: "$19/2500 pcs"
  },
  {
    title: "0.75-1.0 oz Lid",
    image: images['0.75-1.0_oz__Lid.jpg'],
    price: "$15/2500 pcs"
  },
  {
    title: "20 oz Clear Cup",
    image: images['20_oz__Clear_Cup.jpg'],
    price: "$49/1000 pcs"
  },
  {
    title: " 16 oz Clear Cup",
    image: images['_16_oz__Clear_Cup.jpg'],
    price: "$46/1000 pcs"
  },
  {
    title: "20-16 oz Clear Cup Lid",
    image: images['20-16_oz__Clear_Cup_Lid.jpg'],
    price: "$18/1000 pcs"
  },
  {
    title: "32 oz White Rectangular Container/Lid",
    image: images['32_oz_White_Rectangular__Container:Lid.jpg'],
    price: "$26/150 pcs"
  },
  {
    title: "28 oz White Rectangular Container/Lid",
    image: images['28_oz_White_Rectangular__Container:Lid.jpg'],
    price: "$25/150 pcs"
  },
  {
    title: "24 oz White Rectangular Container/Lid",
    image: images['24_oz_White__Rectangular__Container:Lid (1).jpg'],
    price: "$22/150 pcs"
  },
  {
    title: "16 oz White Rectangular Container/Lid",
    image: images['16_oz_White__Rectangular__Container:Lid.jpg'],
    price: "$21/150 pcs"
  },
  {
    title: "12 oz White Rectangular Container/Lid",
    image: images['12_oz_White__Rectangular__Container:Lid (1).jpg'],
    price: "$20/240 pcs"
  },
  {
    title: "10 oz White Round Bowl",
    image: images['10_oz__White_Round__Bowl.jpg'],
    price: "$29/500 pcs"
  },
  {
    title: "15x17 Double Layer Napkins",
    image: images['15x17__Double_Layer__Napkins.jpg'],
    price: "$32/4000 pcs"
  },
  {
    title: "9x9 Cocktail Napkin",
    image: images['9x9__Cocktail_Napkin.jpg'],
    price: "$18/4000 pcs"
  },
  {
    title: "7x13 Take-out Napkin",
    image: images['7x13_Take-out__Napkin.jpg'],
    price: "$28/15000 pcs"
  },
  {
    title: " 8x8x1 Clear Seal Plastic Container",
    image: images['_8x8x1_Clear_Seal__Plastic_Container.jpg'],
    price: "$49/200 pcs"
  },
  {
    title: "6x6x3 Clear Seal Plastic Containers",
    image: images['6x6x3_Clear_Seal__Plastic_Containers.jpg'],
    price: "$49/300 pcs"
  },
  {
    title: "White HD Fork",
    image: images['White_HD__Fork.jpg'],
    price: "$18/1000 pcs"
  },
  {
    title: "White HD Soup Spoon",
    image: images['White_HD__Soup_Spoon.jpg'],
    price: "$18/1000 pcs"
  },
  {
    title: "White HD Knife",
    image: images['White_HD__Knife.jpg'],
    price: "$18/1000 pcs"
  },
  {
    title: "Black HD Fork",
    image: images['Black_HD__Fork.jpg'],
    price: "$18/1000 pcs"
  },
  {
    title: "Black HD Soup Spoon",
    image: images['Black_HD__Soup_Spoon.jpg'],
    price: "$18/1000 pcs"
  },
  {
    title: "Black HD Knife",
    image: images['Black_HD_Knife.jpg'],
    price: "$18/1000 pcs"
  },
  {
    title: "White Fork",
    image: images['White_Fork.jpg'],
    price: "$8/1000 pcs"
  },
  {
    title: "White Spoon",
    image: images['White_Spoon.jpg'],
    price: "$8/1000 pcs"
  },
  {
    title: "White Soup Spoon",
    image: images['White__Soup_Spoon.jpg'],
    price: "$8/1000 pcs"
  },
  {
    title: "Chopsticks",
    image: images['Chopsticks(58).jpg'],
    price: "$13/580 pcs"
  },
  {
    title: "Chopsticks",
    image: images['Chopsticks(59).jpg'],
    price: "$31/1000 pcs"
  },
  {
    title: "Round Clear 8 oz Container w/ Lid",
    image: images['Round_Clear_8_oz__Container_w:_Lid.jpg'],
    price: "$25/500 pcs"
  },
  {
    title: "Round Clear 12 oz Container w/ Lid",
    image: images['Round_Clear_12_oz__Container_w:_Lid.jpg'],
    price: "$26/500 pcs"
  },
  {
    title: "Round Clear 16 oz Container w/ Lid",
    image: images['Round_Clear_16_oz__Container_w:_Lid.jpg'],
    price: "$27/500 pcs"
  },
  {
    title: "3 Compartment Microwave Safe Food Container",
    image: images['3_Compartment__Microwave_Safe__Food_Container.jpg'],
    price: "$34/250 pcs"
  },
  {
    title: "4 Compartment Microwave Safe Food Container",
    image: images['4_Compartment__Microwave_Safe__Food_Container.jpg'],
    price: "$34/250 pcs"
  },
  {
    title: "5 compartment Microwave Safe Food Container",
    image: images['5_compartment_Microwave_safe_Food_container.jpg'],
    price: "$29/100 pcs"
  },
  
];

function App() {

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  }

  const filteredProducts = data.filter((product) => {
    return product.title.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className="App">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container px-4 px-lg-5">
          {/* <a className="navbar-brand" href="#!">D&A Eastern Restaurant Supply</a> */}
          <img src={logo} className="navbar-brand" style={{ width: '350px', height: 'auto' }} alt="Logo" />
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-4">
              <li className="nav-item"><a className="nav-link active" aria-current="page" href="#!">Products</a></li>
            </ul>
            <form className="d-flex">
              (609)-350-3571
            </form>
          </div>
        </div>
      </nav>
      <header className="bg-dark py-5">
        <div className="container px-4 px-lg-5 my-5">
          <div className="text-center text-white">
            <h1 className="display-4 fw-bolder">Container Catalog</h1>
          </div>
        </div>
      </header>
      <section className="py-5">
        <div className="container px-4 px-lg-5 mt-2">
          <div className="row mb-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Search by name"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="row gx-4 gx-lg-5 row-cols-1 row-cols-md-3 row-cols-xl-4 justify-content-center">
              {filteredProducts.map((product, index) => (
              <ProductCard key={index} title={product.title} img={product.image} price ={product.price}/>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

function ProductCard({title, img, price}) {
  return (
    <div className="col mb-5">
      <div className="card h-100">
        <img className="card-img-top" src={img} alt={title} />
        <div className="card-body p-4">
          <div className="text-center">
            <h5 className="fw-bolder">{title}</h5>
            <h6 className="price">{price}</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
